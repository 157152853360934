export const slugAndIdDelimiter = "-d"

export const extractSlugAndId = (url: string): { slug?: string; id?: string } => {
  const delimiterIndex = url.lastIndexOf(slugAndIdDelimiter)
  if (delimiterIndex === -1) {
    return {}
  }

  const id = url.substring(delimiterIndex + slugAndIdDelimiter.length)
  const slug = url.substring(0, delimiterIndex)

  return { slug, id }
}
