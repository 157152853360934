interface TextField {
  type: string
  text: string
  spans: any[]
}

interface Link {
  linkType: string
  url: string
}

export enum CourseType {
  inPerson = "in person",
  online = "online",
  preliminary = "preliminary",
  onlineEvent = "onlineEvent",
  product = "product",
  inPersonEvent = "inPersonEvent"
}

export enum CourseVariant {
  course = "course",
  projectDays = "projectDays",
  sampleCourse = "sampleCourse",
  workshop = "workshop",
  product = "product"
}

export enum CourseAgeGroup {
  adult = "adult",
  children = "children"
}

export const getCourseAgeGroupLabel = (ageGroup: CourseAgeGroup): string => {
  switch (ageGroup) {
    case CourseAgeGroup.children:
      return "Kurzy pro děti"
    case CourseAgeGroup.adult:
      return "Kurzy pro dospělé"
    default:
      return "Kurzy"
  }
}

export interface CourseData {
  additionalInformation: TextField[]
  content: TextField
  courseFrom: string
  courseTo: string
  excerpt: TextField[]
  price: number
  registrationGoogleSpreadsheetUrl: Link
  registrationOpenFrom: string
  registrationOpenTo: string
  title: TextField[]
  type: CourseType
  where: TextField[]
}
