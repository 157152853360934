import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types"
import { List, Typography } from "antd"
import Link from "next/link"
import React from "react"
import { nanoid } from "nanoid"

export const documentToRenderOptions = {
  renderMark: {
    [MARKS.BOLD]: (text) => <Typography.Text strong={true}>{text}</Typography.Text>,
    [MARKS.ITALIC]: (text) => <Typography.Text italic={true}>{text}</Typography.Text>,
    [MARKS.UNDERLINE]: (text) => <Typography.Text underline={true}>{text}</Typography.Text>,
    [MARKS.CODE]: (text) => <Typography.Text code={true}>{text}</Typography.Text>
  },
  renderNode: {
    [INLINES.ASSET_HYPERLINK]: (node, children) => (
      <Link key={nanoid()} href={node.data.uri}>
        {children}
      </Link>
    ),
    /*[INLINES.EMBEDDED_ENTRY]: (node, children) => <>{children}</>,
    [INLINES.EMBEDDED_RESOURCE]: (node, children) => console.log({ node, children }),
    [INLINES.ENTRY_HYPERLINK]: (node, children) => console.log({ node, children }),*/
    [INLINES.HYPERLINK]: (node, children) => (
      <Link key={nanoid()} href={node.data.uri}>
        {children}
      </Link>
    ),
    /*[INLINES.RESOURCE_HYPERLINK]: (node, children) => console.log({ node, children }),*/
    [BLOCKS.HEADING_1]: (node, children) => (
      <Typography.Title key={nanoid()} level={1}>
        {children}
      </Typography.Title>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <Typography.Title key={nanoid()} level={2}>
        {children}
      </Typography.Title>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <Typography.Title key={nanoid()} level={3}>
        {children}
      </Typography.Title>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
      <Typography.Title key={nanoid()} level={4}>
        {children}
      </Typography.Title>
    ),
    [BLOCKS.HEADING_5]: (node, children) => (
      <Typography.Title key={nanoid()} level={5}>
        {children}
      </Typography.Title>
    ),
    [BLOCKS.HEADING_6]: (node, children) => (
      <Typography.Title key={nanoid()} level={5}>
        {children}
      </Typography.Title>
    ),
    [BLOCKS.PARAGRAPH]: (node, children) => <Typography.Paragraph key={nanoid()}>{children}</Typography.Paragraph>
    /* better is not use Antd
    [BLOCKS.OL_LIST]: (node, children) => <List>{children}</List>,
    [BLOCKS.UL_LIST]: (node, children) => <List>{children}</List>,
    [BLOCKS.LIST_ITEM]: (node, children) => <List.Item>{children}</List.Item>*/
  },
  renderText: (text) => text.replace("!", "?")
}
