import { theme } from "../../styles/styles"
import { htmlSerializer } from "./htmlSerializer"
import { CourseTechnology } from "./mapData"
import { PrismicDocument } from "@prismicio/types"
import { asText } from "@prismicio/helpers"
import { RichText } from "prismic-reactjs"
import { Entry } from "contentful"
import { TypeProductEntitySkeleton, TypeTechnologySkeleton } from "../contentful/ContentfulVzdelanibudoucnostiTypes"
import { PrismicCourseOrContentfulProduct } from "../contentful/PrismicCourseOrContentfulProduct"

const emptyTechnology = {
  name: "",
  slug: "",
  color: theme.colors["main-color-blue"],
  content: ""
}

export const getCourseTechnology = (d: PrismicCourseOrContentfulProduct): CourseTechnology => {
  const prismicData = d?.["id"] as PrismicDocument<any> | undefined

  if (prismicData) {
    if (!prismicData?.data?.technology?.data) {
      return emptyTechnology
    }

    return {
      name: prismicData.data.technology.data.name[0].text,
      description: asText(prismicData.data.technology.data.description),
      slug: prismicData.data.technology.uid,
      color: theme.colors?.[prismicData.data.technology.data?.color] || theme.colors["main-color-blue"],
      content: prismicData.data?.technology?.data?.content ? (
        <RichText render={prismicData.data.technology.data.content} htmlSerializer={htmlSerializer} />
      ) : null
    }
  }

  const {
    fields: { technology }
  } = d as Entry<TypeProductEntitySkeleton, undefined, string>

  const firstTechnology = technology?.[0] as Entry<TypeTechnologySkeleton, undefined, string> | undefined
  if (!firstTechnology) {
    return emptyTechnology
  }

  return {
    name: firstTechnology.fields.title,
    color: firstTechnology.fields.color ?? theme.colors["main-color-blue"],
    content: firstTechnology.fields.content,
    slug: firstTechnology.fields.slug,
    description: firstTechnology.fields.description
  }
}
